@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Work Sans", sans-serif;
}

:root {
	/* ===== Colors ===== */
	--body-color: #e4e9f7;
	--sidebar-color: #fff;
	--primary-color: #274775;
	--primary-color-light: #f6f5ff;
	--success-color: #228b22;
	--toggle-color: #ddd;
	--text-color: #707070;
	--clr-white: #fff;
	--clr-black: #000;
	--clr-red-light: #f5e1d7;
	--clr-red: #bd3432;
	--clr-red-dark: #6b1210;
	--clr-orange: #ff7600;
	--clr-yellow: #ffb300;
	--clr-yellow-lighter: #fff6af;
	--clr-yellow-light: #fffbde;
	--clr-green: #85c656;
	--clr-green-light: #eaf5d7;
	--clr-gray-light: #efefef;
	--clr-gray: #d2d2d2;
	--clr-gray-darker: #adadad;
	--clr-gray-darkest: #7c7c7c;
	--clr-purple-lightest: #f6faff;
	--clr-purple-light: #ecebf6;
	--clr-purple: #605cc7;
	--clr-purple-dark: #292b6b;

	/* ====== Transition ====== */
	--tran-03: all 0.2s ease;
	--tran-03: all 0.3s ease;
	--tran-04: all 0.3s ease;
	--tran-05: all 0.3s ease;
}

/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
	line-height: 1.15;
	/* 1 */
	-webkit-text-size-adjust: 100%;
	/* 2 */
}

/* Sections
     ========================================================================== */
/**
   * Remove the margin in all browsers.
   */
body {
	margin: 0;
}

/**
   * Render the `main` element consistently in IE.
   */
main {
	display: block;
}

/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
	box-sizing: content-box;
	/* 1 */
	height: 0;
	/* 1 */
	overflow: visible;
	/* 2 */
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
	font-family: monospace, monospace;
	/* 1 */
	font-size: 1em;
	/* 2 */
}

/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background on active links in IE 10.
   */
a {
	background-color: transparent;
}

/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
	border-bottom: none;
	/* 1 */
	text-decoration: underline;
	/* 2 */
	text-decoration: underline dotted;
	/* 2 */
}

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
	font-weight: bolder;
}

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
	font-family: monospace, monospace;
	/* 1 */
	font-size: 1em;
	/* 2 */
}

/**
   * Add the correct font size in all browsers.
   */
small {
	font-size: 80%;
}

/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

/* Embedded content
     ========================================================================== */
/**
   * Remove the border on images inside links in IE 10.
   */
img {
	border-style: none;
}

/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	/* 1 */
	font-size: 100%;
	/* 1 */
	line-height: 1.15;
	/* 1 */
	margin: 0;
	/* 2 */
}

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
	/* 1 */
	overflow: visible;
}

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
	/* 1 */
	text-transform: none;
}

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/**
   * Correct the padding in Firefox.
   */
fieldset {
	padding: 0.35em 0.75em 0.625em;
}

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
	box-sizing: border-box;
	/* 1 */
	color: inherit;
	/* 2 */
	display: table;
	/* 1 */
	max-width: 100%;
	/* 1 */
	padding: 0;
	/* 3 */
	white-space: normal;
	/* 1 */
}

/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
	vertical-align: baseline;
}

/**
   * Remove the!important vertical scrollbar in IE 10+.
   */
textarea {
	overflow: auto;
}

ul {
	list-style: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
}

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type="checkbox"],
[type="radio"] {
	box-sizing: border-box;
	/* 1 */
	padding: 0;
	/* 2 */
}

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type="search"] {
	-webkit-appearance: textfield;
	/* 1 */
	outline-offset: -2px;
	/* 2 */
}

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
	-webkit-appearance: button;
	/* 1 */
	font: inherit;
	/* 2 */
}

/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
	display: block;
}

/*
   * Add the correct display in all browsers.
   */
summary {
	display: list-item;
}

/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
	display: none;
}

/**
   * Add the correct display in IE 10.
   */
[hidden] {
	display: none;
}

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	box-sizing: border-box;
}

figure {
	margin: 0;
}

body {
	min-height: 100vh;
	background-color: var(--body-color);
	transition: var(--tran-05);
}

.container-dashboard {
	margin: 0 35px;
}

::selection {
	background-color: var(--primary-color);
	color: #fff;
}

/* ===== Sidebar ===== */
.sidebar {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 250px;
	padding: 10px 0px;
	background: var(--sidebar-color);
	transition: var(--tran-05);
	border-right: 1px solid var(--body-color);
	z-index: 100;
}

.sidebar.close {
	width: 78px;
}

/* ===== Reusable code - Here ===== */
.sidebar li {
	height: 50px;
	list-style: none;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.sidebar header .image,
.sidebar .icon {
	min-width: 60px;
	border-radius: 6px;
}

.sidebar .icon {
	min-width: 25px;
	border-radius: 6px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	margin: 0 15px;
	margin-left: 10px;
}

.sidebar .text,
.sidebar .icon {
	color: var(--text-color);
	transition: var(--tran-03);
}

.sidebar .text {
	font-size: 16px;
	font-weight: 500;
	white-space: nowrap;
	opacity: 1;
}

.sidebar.close .text {
	opacity: 0;
}

/* =========================== */

.sidebar header {
	position: relative;
}

.sidebar header .image-text {
	display: flex;
	align-items: center;
}

.sidebar header .logo-text {
	display: flex;
	flex-direction: column;
}

header .image-text .name {
	margin-top: 2px;
	font-size: 18px;
	font-weight: 600;
}

header .image-text .profession {
	font-size: 16px;
	margin-top: -2px;
	display: block;
}

.sidebar header .image {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 10px;
	margin-top: 5px;
}

.sidebar header .image img {
	width: 40px;
	border-radius: 6px;
}

.sidebar header .toggle {
	position: absolute;
	top: 189%;
	right: -13px;
	transform: translateY(-50%) rotate(180deg);
	height: 25px;
	width: 25px;
	background-color: var(--success-color);
	color: var(--sidebar-color);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	cursor: pointer;
	transition: var(--tran-05);
}

body.dark .sidebar header .toggle {
	color: var(--text-color);
}

.sidebar.close .toggle {
	transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu {
	margin-top: 20px;
}

.sidebar li.search-box {
	border-radius: 6px;
	background-color: var(--primary-color-light);
	cursor: pointer;
	transition: var(--tran-05);
}

.sidebar li.search-box input {
	height: 100%;
	width: 100%;
	outline: none;
	border: none;
	background-color: var(--primary-color-light);
	color: var(--text-color);
	border-radius: 6px;
	font-size: 17px;
	font-weight: 500;
	transition: var(--tran-05);
}

.sidebar li a {
	list-style: none;
	height: 100%;
	background-color: transparent;
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
	text-decoration: none;
}

.sidebar li a:hover {
	/* background-color: var(--primary-color); */
	border-left: 4px solid var(--primary-color);
}

.sidebar li a:hover .icon,
.sidebar li a:hover .text {
	color: var(--primary-color);
}

.sidebar li a.active {
	/* background-color: var(--primary-color); */
	border-left: 4px solid var(--primary-color);
}

.sidebar li a.active .icon,
.sidebar li a.active .text {
	color: var(--primary-color);
}

.sidebar .menu-bar {
	height: calc(100% - 19px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar {
	display: none;
}

.sidebar .menu-bar .mode {
	border-radius: 6px;
	background-color: var(--primary-color-light);
	position: relative;
	transition: var(--tran-05);
}

.home {
	position: absolute;
	top: 0;
	top: 0;
	left: 250px;
	height: 100vh;
	width: calc(100% - 250px);
	background-color: var(--body-color);
	transition: var(--tran-05);
}

.home .text {
	font-size: 30px;
	font-weight: 500;
	color: var(--text-color);
	padding: 12px 60px;
}

.sidebar.close~.home {
	left: 78px;
	height: 100vh;
	width: calc(100% - 78px);
}

.bottom-content {
	background-color: var(--primary-color);
	padding: 30px 10px;
}

.bottom-content .title {
	color: var(--toggle-color);
	font-size: 14px;
	margin-left: 30px;
	display: block;
}

.bottom-content .title.close {
	display: none;
}

.bottom-content .userInfo {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.userInfo .img img {
	border-radius: 50%;
	width: 4vmin;
	border: 3px solid var(--body-color);
	height: 4vmin;
	object-fit: cover;
	object-position: center;
	transition: all 0.2s ease;
}

.userInfo .info {
	display: block;
}

.userInfo .info.close {
	display: none;
}

.userInfo .info h5 {
	color: var(--body-color);
	font-size: 19px;
}

.userInfo .info p {
	font-weight: lighter;
	font-size: 14px;
	color: var(--sidebar-color);
}

.header {
	width: 100%;
	height: 100px;
	background: var(--sidebar-color);
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	top: 0;
	display: flex;
	flex-direction: row-reverse;
}

.hInfo {
	display: flex;
	align-items: center;
}

.headerInfo {
	margin-left: 12px;
}

.headerInfo h5 {
	color: #00008b;
	font-size: 15px;
}

.headerInfo p {
	color: var(--text-color);
	font-size: 12px;
	display: inline-block;
	margin-right: 2px;
}

.headerInfo svg {
	cursor: pointer;
	fill: var(--text-color);
}

.header ul {
	display: flex;
	margin: 0;
	padding: 7px 35px;
	justify-content: flex-end;
}

.search {
	margin: 30px 0px;
}

.field {
	display: flex;
	position: realtive;
	width: 100%;
}

.field>input[type="text"],
.field>button {
	display: block;
}

.field>input[type="text"] {
	flex: 1;
	padding: 0.8em 1rem;
	border: 0.2em;
	border-radius: 5px;
}

.field>button {
	padding: 0.6em 3em;
	background-color: var(--primary-color);
	color: white;
	border: none;
	border-radius: 5px;
}

.field>button:hover {
	cursor: pointer;
}

.card .card-header {
	padding: 1.5rem 1.5rem;
	margin-bottom: 0;
	background-color: transparent;
	border-bottom: 0 solid rgba(34, 41, 47, 0.125);
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.card .card-title {
	font-weight: 500;
	font-size: 1.285rem;
}

.card-body {
	flex: 1 1 auto;
	padding: 1.5rem 1.5rem;
}

.card .card-header+.card-body {
	padding-top: 0px;
}

.card {
	margin-bottom: 2rem;
	box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
	transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

.card .card {
	box-shadow: none !important;
}

.card .card-title {
	font-weight: 500;
	font-size: 1.285rem;
	margin-bottom: 1.53rem;
}

.card .card-img {
	object-fit: cover;
}

.card .card-img-overlay {
	border-radius: 0.428rem;
}

.card.card-fullscreen {
	display: block;
	z-index: 9999;
	position: fixed;
	width: 100% !important;
	height: 100% !important;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	overflow: auto;
}

.card .card-body[class*="border-bottom-"] {
	border-bottom-width: 2px !important;
}

.card .card-img-overlay.bg-overlay {
	background: rgba(34, 41, 47, 0.45);
}

.card .card-img-overlay .text-muted {
	color: #1e1e1e !important;
}

.card.card-minimal {
	border: none;
	box-shadow: none;
}

.card .card-header {
	position: relative;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
}

.card .card-header .card-title {
	margin-bottom: 0;
}

/* Tab */
.nav-tabs {
	margin-bottom: 1rem;
	position: relative;
}

.nav-tabs .nav-item {
	position: relative;
	cursor: pointer;
}

.nav-tabs .nav-link {
	color: #6e6b7b;
	border: none;
	min-width: auto;
	font-weight: 450;
	padding: 0.61rem 1.2rem;
	border-radius: 0;
	position: relative;
	overflow: hidden;
}

.nav-tabs .nav-link:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3px;
	background: linear-gradient(30deg,
			#7367f0,
			rgba(115, 103, 240, 0.5)) !important;
	transition: transform 0.3s;
	transform: translate3d(0, 150%, 0);
}

.nav-tabs .nav-link.active {
	position: relative;
	color: var(--primary-color);
}

.nav-tabs .nav-link.active:after {
	transform: translate3d(0, 0, 0);
}

.nav-tabs .nav-link.dropdown-toggle::after {
	top: 1px;
	left: 1px;
}

/* DATATABLE */

.react-dataTable {
	border-radius: 0;
	padding: 25px 50px;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow {
	min-height: 0;
	height: 38px;
}

.gZWIKX {
	border: none !important;
}

.eUeqdG {
	color: var(--clr-black);
	font-weight: bold;
	font-size: 12px;
}

.jxflYm {
	border: none !important;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
	padding: 0.72rem 1.5rem;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
	color: #ebe9f1;
	font-weight: bold;
	font-style: 0.857rem;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable:hover {
	opacity: 1;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable span {
	margin-left: 0.35rem;
}

.react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child .form-check {
	transform: translateX(-10px);
}

.react-dataTable .rdt_TableBody .rdt_TableRow {
	color: #6e6b7b;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	height: 53px;
	padding: 0.72rem 1.5rem;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action svg {
	stroke: #ebe9f1;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action .dropdown-item:hover svg {
	stroke: #1e90ff;
}

.react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action .dropdown-item:active svg {
	stroke: #fff;
}

.react-dataTable .react-paginate.separated-pagination .page-item:first-child .page-link,
.react-dataTable .react-paginate.separated-pagination .page-item:last-child .page-link {
	border-radius: 50%;
}

.react-dataTable.roles-table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child .form-check {
	transform: translateX(0);
}

.react-dataTable.roles-table .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child .form-check {
	transform: translateX(10px);
}

@media (max-width: 740px) {
	.permission-header .add-permission {
		margin-top: 1rem !important;
	}
}

.rdt_Pagination select {
	min-width: 30px;
}

.rdt_Pagination select:focus,
.rdt_Pagination select:active {
	outline: 0;
}

.dataTable-filter {
	max-width: 220px;
}

.dataTable-select {
	width: 5rem;
}

.dataTable-select:not([multiple="multiple"]) {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	background-position: calc(100% - 12px) 10px, calc(100% - 20px) 12px, 100% 0;
	background-size: 10px 10px, 10px 10px;
}

.dark-layout .app-user-list header {
	background-color: #283046;
}

.dark-layout .react-dataTable .hlidmt {
	background-color: #283046;
}

.dark-layout .react-dataTable .khKjDK {
	background-color: #283046;
	color: #b4b7bd;
}

.dark-layout .react-dataTable .rdt_Table {
	background: #343d55;
	color: #b4b7bd;
}

.dark-layout .react-dataTable .rdt_Table>div:first-child:not(.rdt_TableHead):not(.rdt_TableBody) {
	color: #b4b7bd;
	background-color: #283046;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow {
	background-color: #343d55;
	border-color: #3b4253;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
	color: #3b4253;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol div[role="button"] {
	color: #3b4253;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol_Sortable {
	color: #d0d2d6;
}

.dark-layout .react-dataTable .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol_Sortable svg {
	stroke: #d0d2d6;
}

.dark-layout .react-dataTable .rdt_TableBody .expandable-content {
	background-color: #283046;
	color: #b4b7bd;
}

.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow {
	background-color: #283046;
	color: #b4b7bd;
	border-color: #3b4253;
}

.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell {
	color: #b4b7bd;
}

.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow .rdt_TableCell .column-action svg {
	stroke: #b4b7bd;
}

.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow button:hover:not(:disabled) {
	background-color: #161d31;
}

.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow button svg {
	color: #b4b7bd;
}

.dark-layout .react-dataTable .rdt_TableBody .rdt_TableRow .permissions-actions button:hover {
	background-color: transparent;
}

.dark-layout .react-dataTable .rdt_Pagination {
	color: #b4b7bd;
	border-color: #3b4253;
	background-color: #283046;
}

.dark-layout .react-dataTable .rdt_Pagination svg {
	fill: #b4b7bd;
}

.dark-layout .rdt_Pagination {
	color: #b4b7bd;
	background-color: #283046;
}

.dark-layout .rdt_Pagination button svg {
	fill: #b4b7bd;
}

.dark-layout .rdt_Pagination button[disabled] svg {
	fill: #676d7d;
}

/* pagination */
.pagination {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 14px;
	margin: 25px 0px -20px -8px;
}

.pagination .page__nav {
	display: flex;
	margin: 1px;
}

.pagination .page__nav .page__numbers {
	display: flex;
}

.pagination .page__nav .page__numbers li {
	margin-left: 0.25rem;
	margin-right: 0.25rem;
}

.pagination .page__nav .page__numbers li .page__number {
	display: inline-flex;
	text-decoration: none;
	padding: 4px 12px;
	justify-content: center;
	align-items: center;
	border-radius: 3px;
	font-size: 16px;
	color: var(--text-color);
}

.kyc-card {
	border-bottom: 1px solid var(--clr-gray) !important;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.gelpCx {
	min-height: 420px !important;
}

.next-btn {
	display: inline-flex;
	text-decoration: none;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;
	border-radius: 11px;
	font-size: 23px;
	border: 1px var(--clr-gray-darkest) solid;
	color: var(--text-color);
}

.next-btn:hover {
	color: var(--clr-gray-darkest);
}

.pagination .page__nav .page__numbers li .page__number.page--current {
	background-color: var(--clr-green);
	color: var(--clr-white);
}

.pagination .page__nav .page__numbers li:first-child {
	margin-left: 0px;
}

.pagination .page__count {
	color: var(--clr-gray-darkest);
	margin-left: 10px;
}

.pagination.hide {
	display: none;
}

.pagination .page__nav .page__numbers li .page__number.blank {
	border: none;
	cursor: default;
}

.pagination .page__nav .page__numbers li {
	cursor: pointer;
}

@media all and (max-width: 767px) {
	.pagination {
		flex-direction: column-reverse;
	}

	.pagination .page__nav {
		margin: 0px;
		margin-right: auto;
	}

	.pagination .page__count {
		width: 100%;
		margin-left: 0px;
		padding-bottom: 5px;
	}
}

.action svg {
	font-size: 25px;
	cursor: pointer;
}

.bMgaAx div:first-child {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 15px;
}

/*  Input  */
.form-control {
	display: block;
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.45;
	color: #6e6b7b;
	-webkit-appearance: none;
	appearance: none;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
	padding: 0.571rem 1rem;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d8d6de;
	border-radius: 0.357rem;
}

.form-control[type="file"] {
	overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
	cursor: pointer;
}

[type="file"]::-webkit-file-upload-button {
	display: none;
}

.input-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.input-group>.form-control {
	position: relative;
	flex: 1 1 auto;
	width: 1%;
	min-width: 0;
}

.input-group-merge .form-control:not(:last-child) {
	padding-right: 0;
	border-right: 0;
}

.input-group-merge .form-control.is-invalid {
	padding-right: 0;
	border-right: 1px solid #dc3545;
	border-top-right-radius: 0.357rem !important;
	border-bottom-right-radius: 0.357rem !important;
}


.react-select.is-invalid {
	border: 1px solid #dc3545 !important;
	border-radius: 6px !important;
}

.react-select.is-invalid .etselect__indicator-separator {
	background-color: #dc3545 !important;
}

.react-select.is-invalid .css-tlfecz-indicatorContainer {
	color: #dc3545 !important;
}

/* .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
} */

.input-group-text {
	display: flex;
	align-items: center;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.45;
	color: #6e6b7b;
	white-space: nowrap;
}

.input-group-text {
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-text {
	padding: 0.571rem 1rem;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d8d6de;
	border-radius: 0.357rem;
}

.cursor-pointer {
	cursor: pointer;
}

svg {
	vertical-align: middle;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.input-group:not(.bootstrap-touchspin):focus-within {
	box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
	border-radius: 0.357rem;
}

.form-control:focus {
	background-color: #fff;
	border-color: #7367f0;
	box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.form-control:focus:valid {
	box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%);
}

.input-group:not(.bootstrap-touchspin):focus-within .form-control {
	border-color: #7367f0;
	box-shadow: none;
}

/* .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
[dir="ltr"] .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
} */

.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
	border-color: #7367f0;
	box-shadow: none;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: -1px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* Button */
button,
select {
	text-transform: none;
}

.waves-effect {
	position: relative;
	overflow: hidden;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1;
	color: #6e6b7b;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	font-size: 1rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		background 0s, border 0s;
}

.btn {
	font-weight: 500;
	margin: 0;
}

.btn {
	width: 100%;
	text-align: center;
	cursor: pointer;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.786rem 1.5rem;
	border-radius: 0.358rem;
	box-shadow: none;
}

.btn-info {
	color: #fff !important;
}

.btn-info {
	border-color: #00cfe8 !important;
	background-color: #00cfe8 !important;
}

button:not(:disabled),
[dir] [type="button"]:not(:disabled),
[dir] [type="reset"]:not(:disabled),
[dir] [type="submit"]:not(:disabled) {
	cursor: pointer;
}

.btn-secondary {
	color: #fff !important;
}

.btn-secondary {
	border-color: #82868b !important;
	background-color: #82868b !important;
}

.card-kyc {
	min-height: 48rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.auth {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.form {
	margin: 50px auto;
	background-color: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 20px;
	width: 400px;
}

.headers {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.form hr {
	position: relative;
	margin: 0 auto;
	opacity: 0.5;
	left: -19px;
	width: 110%;
	border-bottom: 1px linen var(--primary-color);
}

.headers>div {
	text-align: center;
}

.title {
	color: var(--primary-color);
	font-family: "Work Sans", sans-serif;
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.005em;
	margin: 10px 10px 30px 10px;
}

.close-modal {
	color: var(--primary-color);
	font-family: sans-serif;
	font-size: 30px;
	font-weight: 200;
	text-decoration: none;
	margin-top: 30px;
	margin-right: 20px;
}

.input-container {
	height: 50px;
	position: relative;
	width: 100%;
}

.ic1 {
	margin-top: 40px;
}

.ic2 {
	margin-top: 30px;
}

.input {
	background-color: transparent;
	border-radius: 8px;
	border: 1px solid #d4dae3;
	box-sizing: border-box;
	color: #879ab5;
	font-size: 14px;
	height: 100%;
	outline: 0;
	padding: 4px 20px 0;
	width: 100%;
}

.input-error {
	border-color: red;
}

.input-container i {
	position: absolute;
	right: 12px;
	top: 16px;
	color: gray;
}

.input-icon {
	height: 17px;
}

.placeholders {
	color: #65657b;
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	left: 20px;
	font-size: 17px;
	line-height: 14px;
	pointer-events: none;
	position: absolute;
	transform-origin: 0 50%;
	transition: transform 200ms, color 200ms;
	top: 20px;
}

.input:disabled {
	background-color: #efefef;
}

.input:focus~.placeholders,
.input:not(:placeholder-shown)~.placeholders {
	transform: translateY(-15px) scale(0.7);
}

.input:not(:placeholder-shown)~.placeholders {
	color: #808097;
}

.input:focus~.placeholders {
	color: #808097;
}

.password-suggestion {
	font-family: "Work Sans";
	font-style: normal;
	text-align: left;
	width: 100%;
	padding: 8px;
	display: flex;
	flex: wrap;
	color: #879ab5;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
}

.form-details {
	margin-top: 65px !important;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

.submit {
	background-color: var(--primary-color);
	border-radius: 12px;
	border: 0;
	box-sizing: border-box;
	color: #eee;
	cursor: pointer;
	font-size: 18px;
	height: 50px;
	margin-top: 38px;
	outline: 0;
	text-align: center;
	width: 100%;
}

.submit:active {
	background-color: var(--primary-color);
}

.form-footer {
	text-align: center;
	margin-top: 25px;
}

.sub-text {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: var(--primary-color);
	text-decoration: none;
}

.sign-up {
	font-family: "Work Sans";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: var(--primary-color);
	text-decoration: none;
}

/* -- Forgot Password -- */
.form-forgot-password {
	background-color: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	height: 343px;
	padding: 20px;
	width: 440px;
}

.form-forgot-password hr {
	position: relative;
	margin: 0 auto;
	opacity: 0.5;
	left: -19px;
	width: 109%;
	border-bottom: 1px linen var(--primary-color);
}

/* -- Sign Up -- */
.form-sign-up {
	width: 850px;
}

.form-sign-up hr {
	position: relative;
	margin: 0 auto;
	opacity: 0.5;
	left: -19px;
	width: 104.8%;
	border-bottom: 1px linen var(--primary-color);
}

.flex-container {
	margin-top: 7px;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
}

.flex-container>div {
	margin-right: 12px;
	margin-left: 12px;
	width: 50%;
	text-align: center;
	line-height: 22px;
	color: var(--primary-color);
}

.input-container img {
	position: absolute;
	right: 12px;
	top: 16px;
	color: gray;
}

.mb-1 {
	margin-bottom: 30px;
}

.error-text-msg {
	color: red;
	text-align: left;
	opacity: 0.7;
	margin: 5px;
	font-size: 14px;
}

.error-dropdown-msg {
	color: red;
	text-align: left;
	opacity: 0.7;
	margin: 8px 5px;
	font-size: 14px;
}

.custom-width {
	width: 95%;
}

.privacy-policy {
	margin: 30px 10px;
}

.text-sky-blue {
	color: skyblue;
}

.ml-4 {
	margin-left: 8px;
}

.signup {
	background-color: var(--primary-color);
	border-radius: 12px;
	border: 0;
	box-sizing: border-box;
	color: #eee;
	cursor: pointer;
	font-size: 18px;
	height: 50px;
	margin-top: 0px;
	outline: 0;
	text-align: center;
	width: 100%;
}

.align-item-center {
	align-items: center !important;
}

@media (max-width: 400px) {
	.flex-container {
		flex-direction: column;
	}
}

.edit-input-container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-end;
}

.demo-inline-spacing {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
}

.btn-success {
	border-color: #28c76f !important;
	background-color: #28c76f !important;
}

.btn-flat-success {
	color: #28c76f;
}

.btn-flat-success {
	background-color: transparent;
}

.btn-flat-success:hover {
	color: #28c76f;
}

.btn-flat-success:active,
.btn-flat-success.active,
.btn-flat-success:focus {
	color: #28c76f;
}

.btn-flat-success:hover:not(.disabled):not(:disabled) {
	background-color: rgba(40, 199, 111, 0.12);
}

.demo-inline-spacing .btn {
	width: auto;
}

.xSqure {
	margin-left: 15px;
}

.btn-success {
	color: #fff !important;
}

.btn-warning {
	color: #fff !important;
}

.btn-warning {
	background-color: #ff9f43;
	border-color: #ff9f43;
}

.actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
}

.modal-footer {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	align-items: center;
	justify-content: flex-start;
}

.modal-footer {
	/* padding: 0.8rem 1.4rem 0.25rem; */
	border-top: 1px solid rgba(34, 41, 47, 0.05);
}

.modal-footer {
	border-bottom-right-radius: 0.357rem;
	border-bottom-left-radius: 0.357rem;
}

.modal .modal-footer {
	padding: 0.8rem 1.4rem;
}

.btn-primary {
	color: var(--clr-white) !important;
	border-color: var(--primary-color) !important;
	background-color: var(--primary-color) !important;
}

button:not(:disabled),
[dir] [type="button"]:not(:disabled),
[dir] [type="reset"]:not(:disabled),
[dir] [type="submit"]:not(:disabled) {
	cursor: pointer;
}

.btn-secondary {
	color: var(--clr-white) !important;
	border-color: #82868b !important;
	background-color: #82868b !important;
}

.radio {
	margin: 0.5rem;
}

.radio input[type="radio"] {
	position: absolute;
	opacity: 0;
}

.radio input[type="radio"]+.radio-label:before {
	content: "";
	background: #274775;
	border-radius: 100%;
	border: 1px solid #070d15;
	display: inline-block;
	width: 1.4em;
	height: 1.4em;
	position: relative;
	top: -0.2em;
	margin-right: 1em;
	vertical-align: top;
	cursor: pointer;
	text-align: center;
	transition: all 250ms ease;
}

.radio input[type="radio"]:checked+.radio-label:before {
	background-color: #605cc7;
	box-shadow: inset 0 0 0 4px #274775;
}

.radio input[type="radio"]:focus+.radio-label:before {
	outline: none;
	border-color: #605cc7;
}

.radio input[type="radio"]:disabled+.radio-label:before {
	box-shadow: inset 0 0 0 4px #274775;
	border-color: #605cc7;
	background: #605cc7;
}

.radio input[type="radio"]+.radio-label:empty:before {
	margin-right: 0;
}

.ml {
	margin-left: 15px;
}

.mr-4 {
	margin-right: 10px;
}

.dropdown {
	cursor: pointer;
}

.dropdown-menu {
	position: absolute;
	z-index: 1000;
	display: none;
	min-width: 10rem;
	font-size: 1rem;
	color: #6e6b7b;
	list-style: none;
}

.dropdown-menu-end {
	--bs-position: end;
}

.dropdown-menu {
	padding: 0.5rem 0;
	margin: 0;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(34, 41, 47, 0.05);
	border-radius: 0.357rem;
}

.dropdown-menu {
	padding: 0.5rem 0;
	margin: 0;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(34, 41, 47, 0.05);
	border-radius: 0.357rem;
}

.dropdown-menu.show {
	display: block;
}

.dropdown-menu {
	transform: scale(1, 0);
	box-shadow: 0 5px 25px rgb(34 41 47 / 10%);
}

.show>.dropdown-menu {
	opacity: 1;
	display: block;
}

.show>.dropdown-menu {
	transform: scale(1, 1);
}

.dropdown-menu[data-popper-placement=bottom-start],
[dir] .dropdown-menu[data-popper-placement=bottom-end] {
	margin-top: 0.4rem !important;
}

.dropdown-menu[data-popper-placement]:not([data-popper-placement^=top-]) {
	top: 0.5rem !important;
}

.dropdown-menu[data-popper-placement]:not([data-popper-placement^=top-]) {
	margin: unset !important;
}

.dropdown-item {
	display: block;
	width: 100% !important;
	font-weight: 400;
	color: #6e6b7b;
	white-space: nowrap;
}

.w-100 {
	width: 100% !important;
}

.dropdown-item {
	padding: 0.65rem 1.28rem;
	clear: both;
	text-align: inherit;
	background-color: transparent;
	border: 0;
}

.dropdown-menu .dropdown-item {
	cursor: pointer;
}

.align-middle {
	vertical-align: middle !important;
}

.ms-50 {
	margin-left: 0.5rem !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #7367f0;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	text-decoration: none;
}

.dropdown-menu .dropdown-item {
	width: auto;
}

.dropdown-item:hover,
[dir] .dropdown-item:focus {
	background-color: rgba(115, 103, 240, 0.12);
}

.dropdown-item.active,
[dir] .dropdown-item:active {
	background-color: #7367f0;
}

.dropdown-menu .dropdown-item {
	cursor: pointer;
}

.dropdown-menu .dropdown-item:focus {
	outline: 0;
}

/* Tosatify */
.Toastify__toast {
	background-color: #fff;
	border-radius: 0.286rem;
	box-shadow: 0 2px 20px 0 rgba(34, 41, 47, 0.08);
	padding: 1rem;
}

.Toastify__toast .Toastify__toast-body,
.Toastify__toast .Toastify__close-button {
	color: #6e6b7b;
}

.Toastify__toast .toastify-header {
	padding-bottom: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Toastify__toast .toastify-header .title-wrapper {
	display: flex;
	align-items: center;
}

.Toastify__toast .toastify-header .title-wrapper .avatar svg {
	height: 0.85rem;
	width: 0.85rem;
}

.Toastify__toast .toastify-header .toast-title {
	color: #5e5873;
	font-weight: 600;
	margin-left: 0.75rem;
	margin-bottom: 0;
}

.text-success {
	color: #28c76f;
	font-weight: bold;
}

.text-danger {
	color: #f44336;
	font-weight: bold;
}

.Toastify__toast .toastify-body {
	color: #6e6b7b;
	padding-left: 2.3rem;
	font-size: 0.85rem;
	font-family: 'Montserrat', Helvetica, Arial, serif;
}

.Toastify__toast .Toastify__close-button {
	opacity: 1;
	margin-top: 1px;
	margin-left: 0.5rem;
}

.Toastify__toast .Toastify__close-button svg {
	height: 0.85rem;
	width: 0.85rem;
	fill: #5e5873;
}

.Toastify__toast .Toastify__close-button:hover svg {
	fill: #5e5873;
}

.Toastify__toast .Toastify__close-button:focus {
	outline: 0;
}

.Toastify__toast.Toastify__toast--default .toast-title {
	color: #274775;
}

.Toastify__toast.Toastify__toast--error .toast-title {
	color: #ea5455;
}

.Toastify__toast.Toastify__toast--error .Toastify__progress-bar {
	background-color: #ea5455;
}

.Toastify__progress-bar {
	bottom: 0;
	border-top-right-radius: 0.357rem;
	border-bottom-right-radius: 0.357rem;
}

.Toastify__progress-bar.Toastify__progress-bar--default {
	background: #274775;
}

@media (max-width: 480px) {
	.Toastify__toast-container .Toastify__toast+.Toastify__toast {
		margin-top: 1rem;
	}
}

.icon-success {
	background-color: #28c76f;
	border-radius: 50%;
}

.icon-danger {
	background-color: #ea5455;
	border-radius: 50%;
}

.avatar .avatar-content {
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.857rem;
	border-radius: 50%;

}

.avatar.avatar-sm .avatar-content {
	width: 24px;
	height: 24px;
}

.avatar.avatar-sm .avatar-content .avatar-icon,
.avatar.avatar-sm .avatar-content i,
.avatar.avatar-sm .avatar-content .toast-icon {
	height: 1rem;
	width: 1rem;
	font-size: 1rem;
}

.Toastify__toast .toastify-header .title-wrapper .avatar .toast-icon {
	height: 0.85rem;
	width: 0.85rem;
	color: #fff;
}

.logo-text {
	padding: 5px 30px
}

.logo-text img {
	width: 100%;
}

.tb {
	border: 1px solid var(--clr-gray-darker);
	border-radius: 10px;
	/* padding: 10px 10px; */
}

.tb-heading {
	font-weight: bold;
	color: var(--primary-color);
	display: flex;
	align-items: center;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: space-between;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid var(--clr-gray-darker);
}

.tb-details-row {
	display: flex;
	padding-left: 10px;
	padding-top: 5px;
	padding-right: 10px;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0;
	padding-bottom: 10px;
	border-bottom: 1px solid var(--clr-gray-darker);
}

.tb-details-col {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* padding: 10px; */
}

.tb-details-row-btn {
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: space-between;
	align-items: center;
	margin: 10px 0;
	padding-bottom: 10px;
}

.tb-details-col-btn {
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* padding: 10px; */
}

.normal_payout_slab {
	font-weight: bold;
	font-size: 15px;
}

.svg-pointer {
	stroke: #28c76f;
	margin-left: 12px;
	cursor: pointer;
}